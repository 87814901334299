/*
* Admin Layout (eBazar)
* @author: Pixelwibes
* @design by: Pixelwibes.
* @event-namespace:eBazar
* Copyright 2021 Pixelwibes
*/
:root {
  --dark-color: #323a45;

  --color-fff: #ffffff;
  --color-100: #f0f0f0;
  --color-200: #eeeeee;
  --color-300: #e0e0e0;
  --color-400: #bdbdbd;
  --color-500: #555555;
  --color-600: #757575;
  --color-700: #616161;
  --color-800: #424242;
  --color-900: #212121;
  --color-000: #000000;

  --border-color: #f0f0f0;
  --card-color: #ffffff;
  --body-color: #f9fbfd;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #212529;

  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;

  --primary-color: #459daf;
  --secondary-color: #459daf;

  --chart-color1: #ed5782;
  --chart-color2: #e5df88;
  --chart-color3: #459daf;
  --chart-color4: #a6a6a4;
  --chart-color5: #c9b8b8;
}

[data-theme="dark"] {
  --dark-color: #323a45;

  --color-fff: #000000;
  --color-100: #212121;
  --color-200: #262727;
  --color-300: #616161;
  --color-400: #757575;
  --color-500: #8f8f8f;
  --color-600: #bdbdbd;
  --color-700: #e0e0e0;
  --color-800: #eeeeee;
  --color-900: #f0f0f0;
  --color-000: #ffffff;

  --border-color: #353535;
  --card-color: #262727;
  --body-color: #0e1b28;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #8f8f8f;

  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;

  --bs-body-bg: #161616;
  --reverse-color: #f9f9f9;
  --bs-border-color: #8f8f8f;
  --bs-heading-color: #f9f9f9;
  --bs-card-color: #8f8f8f;
  --bs-body-color: #8f8f8f;

  [className="theme-cyan"] {
    --primary-color: #00bdaa;
  }

  [className="theme-indigo"] {
    --border-color: #212020;
    --card-color: #101010;
    --body-color: #050505;
  }

  [className="theme-blue"] {
    --primary-color: #459daf;
    --border-color: #1a1f2d;
    --card-color: #10141f;
    --body-color: #0e1b28;
  }

  [className="theme-orange"] {
    --border-color: #092940;
    --card-color: #052133;
    --body-color: #051e2f;
  }

  [className="theme-blush"] {
    --border-color: #242331;
    --card-color: #1a1925;
    --body-color: #15141f;
  }

  [className="theme-red"] {
    --primary-color: #f73e60;
    --border-color: #222335;
    --card-color: #161725;
    --body-color: #11121d;
  }
}

[data-theme="high-contrast"] {
  --dark-color: #323a45;

  --color-fff: #ffffff;
  --color-100: #000000;
  --color-200: #ffffff;
  --color-300: #ffffff;
  --color-400: #ffffff;
  --color-500: #ffffff;
  --color-600: #ffffff;
  --color-700: #ffffff;
  --color-800: #ffffff;
  --color-900: #ffffff;
  --color-000: #ffffff;

  --border-color: #ffffff;
  --card-color: #0e0e0e;
  --body-color: #000000;
  --white-color: #ffffff;
  --black-color: #000000;
  --sidebar-color: #fcfcfc;

  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;

  --text-color: #ffffff;
  --bs-heading-color: #ffffff;
  --bs-card-color: #ffffff;

  .table {
    --bs-table-bg: #000;
  }

  .card {
    --bs-card-color: #ffffff;
    --bs-body-color: #ffffff;
  }
}
[className="theme-tradewind"] {
  --primary-color: #6aab9c;
  --secondary-color: #f7b36b;

  --chart-color1: #6aab9c;
  --chart-color2: #f7b36b;
  --chart-color3: #97b0aa;
  --chart-color4: #4d6e94;
  --chart-color5: #81a1ca;
  --chart-color6: #ed5782;

  --primary-gradient: linear-gradient(45deg, #608bc2, #65a0a9);
}
[className="theme-monalisa"] {
  --primary-color: #ff7b89;
  --secondary-color: #f7cf79;

  --chart-color1: #ed5782;
  --chart-color2: #e5df88;
  --chart-color3: #726a95;
  --chart-color4: #a6a6a4;
  --chart-color5: #c9b8b8;

  --primary-gradient: linear-gradient(45deg, #b15f85, #e27089);
}
[className="theme-cyan"] {
  --primary-color: #00bdaa;
  --secondary-color: #f1ff17;

  --chart-color1: #00bdaa;
  --chart-color2: #c1c0b9;
  --chart-color3: #537791;
  --chart-color4: #9cbf3b;
  --chart-color5: #cdb60c;

  --primary-gradient: linear-gradient(45deg, #009abd, #00bdaa);
}
[className="theme-indigo"] {
  --primary-color: #484c7f;
  --secondary-color: #d8d419;

  --chart-color1: #484c7f;
  --chart-color2: #d8d419;
  --chart-color3: #7394b9;
  --chart-color4: #79b6bd;
  --chart-color5: #a0c1b8;

  --primary-gradient: linear-gradient(45deg, #5a2ee2, #484c7f);
}
[className="theme-blue"] {
  --primary-color: #459daf;
  --secondary-color: #459daf;

  --chart-color1: #ed5782;
  --chart-color2: #e5df88;
  --chart-color3: #459daf;
  --chart-color4: #a6a6a4;
  --chart-color5: #c9b8b8;

  --primary-gradient: linear-gradient(45deg, #45afaf, #0d6efd);
}
[className="theme-green"] {
  --primary-color: #7eb93f;
  --secondary-color: #fffb83;

  --chart-color1: #7eb93f;
  --chart-color2: #b9bc6d;
  --chart-color3: #427a5b;
  --chart-color4: #4e7376;
  --chart-color5: #a6a6a4;

  --primary-gradient: linear-gradient(45deg, #92d758, #2f984d);
}
[className="theme-orange"] {
  --primary-color: #feb019;
  --secondary-color: #35f3fd;

  --chart-color1: #ef7e56;
  --chart-color2: #44558f;
  --chart-color3: #ded5c4;
  --chart-color4: #f59292;
  --chart-color5: #90a4ae;

  --primary-gradient: linear-gradient(45deg, #ce63ca, #feb019);
}
[className="theme-blush"] {
  --primary-color: #ed5782;
  --secondary-color: #ffdd95;

  --chart-color1: #ed5782;
  --chart-color2: #e5df88;
  --chart-color3: #726a95;
  --chart-color4: #a6a6a4;
  --chart-color5: #c9b8b8;

  --primary-gradient: linear-gradient(45deg, #f5ae8a, #ed5782);
}
[className="theme-red"] {
  --primary-color: #ff4560;
  --secondary-color: #f7c56b;

  --chart-color1: #ff5959;
  --chart-color2: #ffad5a;
  --chart-color3: #4f9da6;
  --chart-color4: #c06c84;
  --chart-color5: #f67280;

  --primary-gradient: linear-gradient(45deg, #f5558d, #ff4560);
}

[className="theme-dynamic"] {
  --primary-gradient: linear-gradient(
    45deg,
    var(--primary-color),
    var(--secondary-color)
  );
}

.secondary-color {
  color: var(--secondary-color);
}
.secondary-color-fill {
  fill: var(--secondary-color);
}
