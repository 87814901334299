/***********main layout scss*********/
#ebazar-layout{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* -webkit-box-orient: vertical; */
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

/***********left icon navigation bar*********/
.navigation{
    background-color: var(--card-color);
    width: 60px;
    height: 100vh;
    font-size: 1rem;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: center;
    
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    z-index: 9;

    @media only screen and (max-width: $break-medium - 1px) {
        width: 100%;
        height: 60px;
        flex-direction: row;
        align-items: center;
        position: fixed;
        overflow: hidden;

        .brand-icon{
            svg{
                width: 22px;
                margin-right: 20px;
            }
        }
        .navbar-nav{
            justify-content: flex-end !important;
            padding-right: 50px;
            flex-direction: row !important;
            .flex-grow-1{
                flex-grow: unset !important;
            }
            .nav-link{
                margin-left: 5px;
            }
            .menu-toggle{
                position: fixed;
                right: 6px;
            }
        }
    }
}

.chatBackIcon {
    font-size: 26px;
    margin-right: 13px;
    display: none;
}

@media only screen and (max-width: 991px) {
    .chatBackIcon {
        display: block;
    }
    .displaymobile {
        overflow: hidden;
        width: 100%;
    }
}

@media (max-width: 991px) {
    .sidebar {
        .menu-list {
            min-height: 52vh !important;
        }
    }
 }

/***********Sidebar Main*********/
.sidebar{
    @include transition(ease .1s);
    background: var(--primary-color);
    z-index: 9;
    margin: 25px 12px;
    height: calc(100vh - 50px);
    width: 235px;
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    border-radius: 1.1rem;
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;    

    @media only screen and (max-width: $break-1280 - 1px) {
        position: fixed;
        left: -400px;
        height: 100%;
        margin: 0px;
        border-radius: 0px;
    }

    @media only screen and (min-width: $break-1280 - 1px) {
        @include overflow(scroll);
        overflow-y: auto;
    }

    @media only screen and (max-width: 375px) {
        height:100%;
    }

    
    @media only screen and (max-width: 991px) {
        // .sidebarBottom {
        //     position: absolute;
        //     bottom: 0px;
        //     width: 100%;
        //     left: 50%;
        //     transform: translate(-50%, 0px);
        //     padding: 0px 18px;
        // }
    }

    .form-switch{
        label{
            color: var(--white-color);
            font-size: 15px;
            padding-left: 10px;
        }
        &.theme-rtl{
            padding-left: 2em;
        }
    }

    .sidebar-title{
        color: var(--white-color);
    }

    .menu-list{
        list-style: none;
        padding: 0;
        @include c_overflow(scroll);

        .collapsed{
            padding: 6px 0px;
        }
        
        li[aria-expanded="true"]{
            .m-link{
                color: var(--secondary-color);
            }
            .arrow:before{
                content: "\f10c";
            }
            .sub-menu:before{
                background-color: var(--secondary-color);
            }
        }
        .m-link,
        .ms-link{
            display: flex;
            &:hover,
            &.active{
                color: #fff;
                &::before{
                    display: block;
                }
            }

            &[aria-expanded="true"]{

                .icofont-dotted-down{
                    rotate: 180deg;
                    transition: .5s;
                }
            }
        }
        .m-link{
            color: var(--white-color);
            align-items: center;
            padding: 8px 0;
            font-size: 17px;
        }
        .ms-link{
            color: var(--white-color);
            position: relative;
            padding: 5px 0;
           span{
                font-size: 14px;
            }
        }

        .fa {
            width: 30px;
        }
        .arrow{
            color: var(--white-color);
            opacity: 1;
            font-size: 10px;
        }
        .divider{
            color: var(--white-color);
            border-color: rgba($white, 0.3) !important;
            opacity: 0.5;
        }
        [class^="icofont-"]{
            width: 30px;
        }
        .sub-menu{
            list-style: none;
            position: relative;
            padding-left: 0px;

            li{
                border-bottom: 1px solid #ffffff57;
                padding: 8px 30px;
            }

            &::before{
                background-color: var(--white-color);
                content: "";
                position: absolute;
                height: 100%;
                width: 0px;
                left: 8px;
                top: 0;
            }
            .ms-link::before{
                top:10px;
            }
        }
    }
    .sidebar-mini-btn{
        font-size: 34px;
        &:focus,&:active{
            outline: none !important;
            box-shadow: none !important
        }
        .icofont-bubble-right{
            &:before{
                content: "\ea64";
            }
        }
        @media only screen and (max-width:  $break-small - 1px) {
           display: none;
        }
    }

    &.gradient{
        background: var(--primary-gradient);
        &.sidebar-mini{
            .menu-list{
                .sub-menu{
                    background: var(--primary-gradient);
                }
            }
        }
    }
    &.sidebar-mini{
        text-align: center;
        padding: 1.5rem 0 !important;
        width: 100px;
        z-index: 9999;
        @media only screen and (min-width: $break-1280 - 1px) {
            overflow: visible; 
        }
        .brand-icon{
            display: block;
            .logo-icon{
                margin: 0 auto;
            }
            .logo-text{
                padding-left: 0px;
                padding-top: 8px;
            }
        }
        .sidebar-title span,
        form{
            display: none;
        }

        .menu-list{
            > li {
                position: relative;

                &:hover{
                    .sub-menu{
                        display: block !important;
                    }
                }
            }

            .m-link{
                margin-bottom: 10px;
                span{
                    display: none;
                }
                &:hover{
                    span{
                        display: block;
                        background: var(--primary-color);
                        border-radius: .25rem;
                        box-shadow: 0 10px 40px 10px rgba($dark,.2);
                        width: 200px;
                        padding: 10px 0px 10px 30px;
                        text-align: left;
                        position: absolute;
                        left: 101px;
                        top: 0;
                        font-size: 17px;
                        &.badge{
                            font-size: 13px;
                            &:before{
                                top: 12px;
                            }
                        }
                    }
                }
            }
            .ms-link{
                &::before{
                    left: -16px;
                }
                span{
                    font-size: 17px;
                }
            }
            .fa{
                width: 100%;
            }
            .sub-menu{
                background: var(--primary-color);
                border-radius: .25rem;
                box-shadow: 0 10px 40px 10px rgba($dark,.2);
                width: 200px;
                padding-top: 10px;
                padding-bottom: 10px;
                position: absolute;
                left: 101px;
                top: 0;
                padding-left: 15px;
                &:before{
                    display: none;
                }
                .ms-link::before{
                    top:12px;
                }
            }
            .divider small{
                display: none;
            }
            [class^="icofont-"]{
                width: 100%;
            }
        }

        .fa-arrow-left::before{
            content: "\f061";
        }

        #menu-Components{
            width: 300px;
            li{
                width: 50%;
                float: left;
            }
        }
        #menu-Authentication{
            width: 230px;
        }
        .sub-menu.show{
            display: none;
        }
        .sidebar-mini-btn{
            .icofont-bubble-right{
                &:before{
                    content: "\ea65";
                }
            }
        }
        .form-switch{
            label{
                display: none;
            }
            &.theme-rtl{
                padding-left: 2.5em;
            }
        }
    }
    
    &.open{
        left: 0px;
        @media only screen and (max-width: $break-medium - 1px) {
            left: 0;
            z-index: 99999;
        }
    }

    .brand-icon{  
        display: flex;
        align-items: center;
        justify-content: center;
        .logo-icon{
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            margin-right: 10px;
            border-radius: 50px;
            min-width: 50px;
            min-height: 50px;        

            img{
                width: auto;
                height: 36px;
            }
            i{
                color: $white;
            }
            svg{
                width: 50px;
                path{
                    fill: var(--primary-color);
                }
                @media only screen and (max-width: $break-small - 1px) {
                    width: 25px;
                }
            }
        }
        .logo-text{
            font-weight: bold;
            color: var(--white-color);
            font-size: 18px;
            display: inline-block;
            padding-left: 0px;
            padding-top: 9px;
            @media only screen and (max-width: $break-small - 1px) {
               font-size: 17px;
            }
        }
    } 
}

/***********menu-toggle*********/
.menu-toggle{
    color: var(--text-color);
    @media only screen and (min-width: $break-1280) {
        display: none;
    }
}

/***********main-layout*********/
.main{
    @include c_overflow(scroll);
    height: 100vh;
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    .body{
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-flow: column;
        flex-flow: column;
        display: -webkit-box;
        display: -ms-flexbox;
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        min-width: 0;
    }
}

/***********notifications*********/
.notifications {
    z-index: 9999 !important;
    .pulse,
    .pulse:focus{
        color: var(--text-color) !important;
    }
    .card{
        .card-header{
            background-color: var(--primary-color);
            color: $white;

            .nav-tabs{
                
                .nav-link{
                    color: rgba($white, .2);
                    font-size: 1rem;
                    border: 0;

                    &.active,
                    &:hover{
                        color: var(--secondary-color);
                        background-color: transparent;

                        &::after{
                            content: '';
                            width: 7px;
                            height: 7px;
                            background-color: var(--secondary-color);
                            display: block;
                            border-radius: 50%;
                            margin: 5px auto 0;
                        }
                    }
                }
            }
        }
        .list{
            @include overflow(scroll);
            height: 330px;
            overflow-y: auto;
        }
    }
    .dropdown-menu{
        .w380{
            @media only screen and (max-width:  $break-small - 1px) {
                width: 320px;
            }
            @media only screen and (max-width:  380px) {
              width: 280px;
            }
        }
    }
}

/***********Header-icon-set*********/
.h-right{
    @media only screen and (max-width: 1279px) {
        margin-left: auto;
        padding-right: 15px;
    }
    @media only screen and (max-width: 767px) {
        margin-left: unset;
        padding-right:unset;
    }
    @media only screen and (max-width: 500px) {
       width: 348px;
       justify-content: end;
    }
    @media only screen and (max-width: 400px) {
        width: auto;
        margin-left: auto;
        padding-right: 12px;
    }
    .user-profile{
        .u-info{
            @media only screen and (max-width: 500px) {
               display: none;
            }
        }
        .dropdown-menu{
            .card{
                &.w280{
                    width: 240px;
                }
            }
        }
    }
    .notifications{
        @media (max-width: 480px) {
            display: none;
         }
    }
    .nav-link{
        text-decoration: none;
        outline: none;
    }
}

.sketch-picker{
    position: absolute;
    z-index: 999;
    top: 30%;
}