// bootstrap class overwrite css
.border,
.border-top,
.border-right,
.border-bottom,
.border-left,
.dropdown-divider {
  border-color: var(--border-color);
}
.container-xxl {
  max-width: 1560px;
}
.font-weight-bold {
  font-weight: bold;
}
.table > :not(caption) > * > * {
  padding: 0.8rem 0.8rem;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.small-xs {
  font-size: 13px !important;
}

// .form-control
.bootstrap-tagsinput {
  width: 100%;
  box-shadow: none;
}

.searchBarDesign {
  margin-top: 12px;
  position: relative;
  overflow: hidden;
  input {
    height: 38px;
    padding: 12px 12px;
    border-radius: 6px;
    border: 1px solid #dedede;
    width: 240px;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #459daf;
    }
  }

  button {
    background: #459daf;
    border: 1px solid #459daf;
    width: 38px;
    height: 38px;
    border-radius: 6px;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    position: absolute;
    right: 0px;
  }
}

.form-label {
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.form-control,
.form-select {
  border-color: var(--border-color);
  background-color: var(--card-color);
  color: var(--text-color);
  font-size: 16px;
  &::placeholder {
    color: var(--color-400);
  }
  &:focus {
    box-shadow: 0 0 10px rgba($dark, 0.15);
    background-color: var(--card-color);
    border-color: var(--primary-color);
    color: var(--text-color);
  }

  &.form-control-lg {
    min-height: calc(1.5em + 1.8rem + 2px);

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }

  &.openField {
    border: 1px solid #f00 !important;
  }
}

.OTPdESIGN input {
  width: 60px !important;
  height: 60px;
  margin-right: 45px;
  border: 1px solid #969696;
}
.input-group-text {
  border-color: var(--border-color);
  background-color: var(--color-200);
  color: var(--text-color);
}
.ms-container {
  width: 100%;
}
.badge-info {
  background: var(--primary-color);
}

// form Switches css
.form-switch {
  .form-check-input {
    &:checked {
      background-color: var(--primary-color);
      border-color: var(--border-color);
    }
  }
}
.big-search {
  color: var(--color-fff);
  &:focus {
    color: var(--color-fff);
  }
}
.btn-link {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.bg-white {
  color: var(--text-color);
}
//lineheight
.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
  line-height: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
  line-height: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
  line-height: calc(1.275rem + 0.3vw) !important;
}
// bottom
.btn {
  font-size: $font-size;
  color: var(--text-color);
  &:hover {
    color: var(--text-color);
  }
  &.btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--white-color);
    &.active {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);
    }
  }

  &.btn-outline-primary {
    color: var(--primary-color);
    border-color: var(--primary-color);
    &:hover {
      color: $white;
      background-color: var(--primary-color);
    }
    &.active {
      background-color: var(--primary-color);
      color: var(--white-color);
    }
  }
  &.btn-outline-secondary {
    border-color: var(--border-color);
    &:hover {
      color: $white;
      background-color: var(--secondary-color);
      i {
        color: $white !important;
      }
    }
  }
  &.btn-outline-success {
    color: $color-success;
    border-color: $color-success;
    &:hover {
      background-color: $color-success;
      color: $white;
    }
  }
  &.btn-outline-danger {
    color: $color-danger;
    border-color: $color-danger;
    &:hover {
      background-color: $color-danger;
      color: $white;
    }
  }
  &.btn-outline-info {
    color: $color-info;
    border-color: $color-info;
    &:hover {
      background-color: $color-info;
      color: $white;
    }
  }

  // &.btn-white {
  //     background-color: var(--color-fff);
  //     border-color: var(--color-fff);
  //     color: var(--color-700);
  // }
  &.btn-dark {
    color: var(--white-color);
  }
  &.btn-secondary {
    color: var(--white-color);
  }

  &.btn-lg {
    padding: 0.7rem 1rem;
  }
}
.btn-close {
  color: var(--text-color);
}
// avatar
.avatar {
  width: 40px;
  min-width: 40px;
  height: 40px;

  &.xs {
    width: 16px;
    min-width: 16px;
    height: 16px;
  }
  &.sm {
    width: 22px !important;
    min-width: 22px !important;
    height: 22px !important;
    i {
      font-size: 12px;
      line-height: 12px;
      vertical-align: text-top;
    }
  }
  &.lg {
    width: 56px;
    min-width: 56px;
    height: 56px;
  }
  &.xl {
    width: 80px;
    min-width: 80px;
    height: 80px;
  }
  &.xxl {
    width: 120px;
    min-width: 120px;
    height: 120px;
  }
  &.no-thumbnail {
    background-color: var(--color-300);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.uploadImage {
  // overflow: hidden;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;

  .btn {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #459daf;
    font-size: 40px;
    color: #fff;
    position: relative;
    width: 50px;
    height: 50px;

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &:active {
      background-color: #459daf;
      color: #fff;
    }

    svg {
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }

    // span{
    //     min-width: 30px;
    //     min-height: 30px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     color: #459daf;
    //     font-size: 16px;
    //     background: #fff;
    //     border-radius: 50px;
    //     position: absolute;
    //     left: -8px;
    //     bottom: -12px;
    //     border: 1px solid #459daf;
    // }

    .form-control {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  .imageName {
    min-height: 50px;
    height: auto;
    max-height: 50px;
    border: 1px solid #e3e3e3;
    padding: 0px 20px;
    border-radius: 6px;
    background: #efefef;
    display: flex;
    align-items: center;

    .progress-container {
      position: relative;
      width: 100%;
      max-width: 500px;
    }

    .progressBar {
      width: 100%;
      height: 14px;
      -webkit-appearance: none;
      appearance: none;
    }

    .progressBar::-webkit-progress-bar {
      background-color: #f3f3f3;
      border-radius: 5px;
      box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
    }

    .progressBar::-webkit-progress-value {
      background-color: #459daf;
      border-radius: 5px 0 0 5px;
    }

    .progressBar::-moz-progress-bar {
      background-color: #459daf;
      border-radius: 5px 0 0 5px;
    }

    .progress-value {
      position: absolute;
      top: -20px;
      right: 0px;
      font-size: 14px;
      color: #000;
      font-weight: bold;
    }

    .form-control {
      border: none;
      border-radius: 0px;
      padding: 4px 0px;
      background-color: transparent;

      &:focus {
        outline: none;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.avatar-list {
  margin: 0 0 -0.5rem;
  padding: 0;
  font-size: 0;
  .avatar {
    color: var(--secondary-color);
    font-weight: 600;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 50%;
    display: inline-block;
    background: var(--primary-color) no-repeat center/cover;
    position: relative;
    vertical-align: bottom;
    font-size: 0.875rem;
    user-select: none;
    min-width: 2rem;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
  &.list-inlineblock {
    li {
      display: inline-block;
    }
  }
}

.passwordShow {
  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translate(0%, -50%);
    font-size: 20px;
  }
}

.avatar-list-stacked {
  .avatar {
    margin-right: -0.8em !important;
    box-shadow: 0 0 0 2px $white;
  }
}
.header {
  .avatar-list-stacked {
    @media only screen and (max-width: $break-xsmall - 150px) {
      display: none;
    }
  }
}
.add-member-top {
  @media only screen and (min-width: $break-xsmall - 150px) {
    display: none;
  }
}
// dropdown menu
.dropdown-item:hover,
.dropdown-item:focus {
  background-color: rgba($dark, 0.1);
}
.dropdown-item {
  color: var(--text-color);
  &:hover {
    color: var(--secondary-color);
  }
}
.dropdown-menu {
  font-size: $font-size + 1px;
  color: var(--text-color);
  background-color: var(--body-color);
  li {
    margin: 5px 0px;
  }
  .dropdown-item {
    color: var(--text-color);
    &.active {
      color: var(--secondary-color);
      background-color: var(--primary-color);
    }
    &:active,
    &:focus {
      background-color: var(--primary-color);
      color: var(--secondary-color);
    }
    &:hover {
      color: var(--secondary-color);
    }
  }
}

// custom modal popup css
.modal {
  right: 0;
  left: auto;
  z-index: 99999;

  &.fade {
    .modal-dialog-vertical {
      transform: translateX(-100%);
    }
  }
  &.show {
    .modal-dialog-vertical {
      transform: translateX(0);
    }
  }
  .modal-dialog-vertical {
    margin: 0;
    height: 100%;
    .modal-content {
      height: 100%;
    }
  }
  .modal-header,
  .modal-footer {
    border-color: var(--border-color);
  }
  .modal-content {
    background-color: var(--body-color);
  }
}

.modal {
  &.right {
    z-index: 99999;
    margin-top: 25px;
    margin-bottom: 25px;
    @media only screen and (max-width: 1279px) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
    .modal-dialog {
      height: calc(100vh - 50px) !important;
      position: fixed;
      margin: auto;
      width: 320px;
      height: 100%;
      -webkit-transform: translate3d(0%, 0, 0);
      -ms-transform: translate3d(0%, 0, 0);
      -o-transform: translate3d(0%, 0, 0);
      transform: translate3d(0%, 0, 0);
      @media only screen and (max-width: 1279px) {
        height: 100vh !important;
      }
      @media only screen and (max-width: 767px) {
        height: 100vh !important;
      }
    }
    .modal-content {
      height: 100%;
      border-radius: 1.1rem !important;
      @media only screen and (max-width: 1279px) {
        border-radius: 0px !important;
      }
      .setting-theme,
      .dynamic-block,
      .setting-font,
      .sidebar-gradient {
        border-bottom: 1px solid #e2e2e2;
      }
      .setting-font,
      .setting-mode,
      .dynamic-block {
        .list-group .list-group-item {
          background-color: transparent;
          border-color: transparent;
          padding: 0px !important;
          margin-bottom: 5px;
        }
      }
      .font_setting {
        .form-check-input:checked {
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
          background-image: inherit;
        }
      }
      .setting-mode {
        .form-check-input:checked {
          background-color: var(--secondary-color);
          border-color: var(--secondary-color);
        }
      }
      .dynamic-block {
        .list-group-item {
          .avatar {
            &.xs {
              width: 25px;
              height: 25px;
              min-width: 25px;
              border-radius: 5px !important;
            }
          }
        }
      }
      .modal-footer {
        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }
    .modal-body {
      @include overflow(scroll);
      overflow-y: auto;
    }
    &.fade {
      .modal-dialog {
        right: -320px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
      }
      &.show {
        .modal-dialog {
          right: 10px;
          @media only screen and (max-width: 1279px) {
            right: 0px;
          }
        }
      }
    }
  }
}

// breadcrumb
.breadcrumb {
  background-color: var(--color-200);
}

.commingSoon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  text-align: center;
  font-size: 100px;
  color: #0000000d;

  span {
    display: block;
  }

  img {
    width: 330px;
    display: flex;
    margin: 0 auto;
  }
}

.LogouModal {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;

  button {
    padding: 12px 40px;
    width: 48%;
    margin-top: 10px;
  }
}

// navbar
.navbar-light {
  .navbar-nav {
    .nav-link {
      color: var(--color-500);
      &:hover,
      &:focus {
        color: var(--primary-color);
      }
    }
  }
}

// custom tab ui
.nav-tabs {
  border-color: var(--border-color);
  &.tab-custom {
    border: 1px solid rgba($white, 0.2);
    .nav-link {
      color: $white;
      opacity: 0.7;
      &.active {
        opacity: 1;
        color: var(--primary-color);
      }
    }
  }
  &.tab-card {
    .nav-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      background-color: transparent;
      border-bottom: 3px solid transparent;
      color: var(--color-500);

      &.active {
        border-color: var(--primary-color);
      }
    }
  }
  &.tab-body-header {
    border: 1px solid Var(--primary-color);
    overflow: hidden;
    .nav-link {
      border: 0;
      margin: 0;
      border-radius: 0;
      color: var(--color-500);

      &:hover {
        color: var(--primary-color);
      }

      &.active {
        background-color: var(--primary-color);
        color: $white;
      }
    }
  }
}

// nav pill
.nav-pills {
  .nav-link.active,
  .show > .nav-link {
    background-color: var(--primary-color);
  }
  .nav-link {
    color: var(--text-color);
  }
}
//border
.border-dark {
  border-color: var(--text-color) !important;
}
.border-start {
  border-color: var(--border-color) !important;
}
// list-group design
.list-group-custom,
.list-group {
  .list-group-item {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--text-color);
    &.active {
      background-color: var(--primary-color);
    }
  }
}

// .progress bar design
.progress {
  background-color: var(--color-100);
  &.plan-active {
    background-color: var(--color-300);
  }
}

// table
.table {
  border-color: var(--border-color);
  tr {
    vertical-align: middle;
    th {
      // border-color: var(--text-color);
      color: var(--text-color);
      text-transform: uppercase;
      font-size: 12px;
    }
    td {
      border-color: var(--border-color);
      color: var(--text-color);
    }
    &:hover {
      td {
        color: var(--color-800);
      }
    }
  }
  .table-dark {
    tr {
      th {
        color: var(--white-color);
      }
    }
  }
  &.doc-table {
    tr {
      th {
        border-color: var(--border-color);
      }
    }
  }
}

// pagination
.page-item {
  &.disabled {
    .page-link {
      background-color: var(--border-color);
      border-color: var(--border-color);
    }
  }
  .page-link {
    background-color: var(--card-color);
    border-color: var(--border-color);
    color: var(--primary-color);
  }
  &.active {
    .page-link {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
    }
  }
}

//Side navbar
.side-navbar {
  li {
    margin-bottom: 5px;
    a {
      color: var(--text-color);
    }
  }
}

//button
.btn-outline-secondary.btn-block {
  color: #fff !important;
}

//navbar
.navbar-toggler:focus {
  box-shadow: none;
}

//Date & Time
input[type="date"],
input[type="time"] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-width: 95%;
}

//tageinput
.bootstrap-tagsinput,
.dropify-wrapper {
  border: 1px solid var(--border-color);
  background-color: var(--card-color);
}
