.uploadData {
  p {
    color: var(--Main);
  }

  h5 {
    font-size: 20px;
    font-weight: 600;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      color: #7f8699;
      line-height: 25px;
      font-size: 14px;

      span {
        width: 100px;
      }
      strong {
        color: #1d1d1d;
        font-weight: 600;
      }
      a {
        color: var(--Main);
        text-decoration: none;
      }
    }
  }
}

.addedSocial {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    a {
      display: flex;
      color: var(--Main);
      text-decoration: none;
      align-items: center;
      margin-bottom: 10px;

      .iconBox {
        font-size: 20px;
        margin-right: 10px;
        line-height: 0;
      }
    }
  }
}

.studentsCount {
  display: flex;
  align-items: center;
  justify-content: space-between;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      color: #7f8699;
      line-height: 25px;
      font-size: 14px;

      strong {
        color: #1d1d1d;
        font-weight: 600;
      }
    }
  }
}

.addRow {
  text-decoration: none;
  color: var(--Main);
  justify-content: end;
  display: flex;

  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;

  &:hover {
    color: var(--Main);
  }
}

.studenteditIcon {
  min-width: 135px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: none;
  color: var(--Main) !important;
  padding: 0px;
  font-size: 14px;
  margin-left: 10px;
  float: right;
  background-color: #ffffff !important;

  img {
    height: 10px;
  }
}

.otherNote {
  border-bottom: 1px solid #c7c5c5;
  padding: 10px 0px 15px 0px;

  p {
    strong {
      font-weight: 600;
    }
    span {
      color: var(--Main);
    }
  }
}

.studentbanner {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.viewList {
  margin-top: 15px;
}

.studentadmission {
  background: white;
  display: flex;
  padding: 22px 10px;
  border-radius: 21px;
  border-left: 8px solid;
  border-color: var(--Main);

  .bannerinside {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  ul {
    li {
      span {
        width: 150px;
      }
    }
  }
}

.btnlist {
  ul {
    display: flex;
    padding: 0;
    flex-wrap: wrap !important;
  }
  li {
    list-style: none;
    padding-right: 5px;
    padding-top: 10px;
  }
}

.studentTabbar {
  background-color: white;

  margin: 0px auto;
  display: inline-flex;
  border-radius: 8px;
  padding: 3px 0px;

  .tabbaritem {
    a {
      margin: 0px !important;
      border: none !important;
      background: transparent !important;
      top: 0 !important;
    }
  }
}

.buttonBottom {
  display: flex;
  align-items: center;
  justify-content: space-around;

  :global(.btn) {
    padding: 11px 50px;
    font-size: 16px;
    font-weight: 600;
  }
}

.boxDesign {
  background: #f4f1ff;
  padding: 12px 20px;
  display: block;
  border-radius: 6px;
  transition: 0.5s;

  &:hover {
    background: #459daf;
    color: #fff;
  }

  small {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
  }

  span {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 15px;

    i {
      font-size: 50px !important;
    }
  }
}

.deleteButton {
  position: absolute;
  top: -20px;
  right: -20px;
  background: #459daf !important;
  border-radius: 50px;
  width: 41px;
  height: 41px;
  justify-content: center !important;

  svg {
    color: #fff !important;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: #459daf !important;

    svg {
      color: #fff !important;
    }
  }
}

.switchToggle {
  input[type="checkbox"] {
    position: relative;
    width: 40px;
    height: 20px;
    -webkit-appearance: none;
    appearance: none;
    background: #cdcdcd;
    outline: none;
    border-radius: 2rem;
    cursor: pointer;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #575757;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
  }

  input[type="checkbox"]:checked::before {
    transform: translateX(100%);
    background: #fff;
  }

  input[type="checkbox"]:checked {
    background: #00ac48;
  }
}

.editImageModal {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 999;
}

.editImage {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #459daf;
  border-radius: 50px;
  position: absolute;
  color: #fff;
  right: 0px;
  bottom: 0px;
  font-size: 12px;
}

.tabsTable {
  ul {
    padding: 0px;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;

    li {
      padding: 8px 20px;
      background-color: #e3e2e2;
      border-radius: 4px;
      color: #000;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;

      &:global(.active) {
        background-color: #459daf;
        color: #fff;
      }

      svg {
        font-size: 25px;
      }
    }
  }
}

:global(.dropdown__menu-list) {
  max-height: 190px !important;
}

.modalButtonBottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  :global(.btn) {
    padding: 12px 24px;
    width: 48%;
    margin-top: 10px;
  }
}

.labelTitle {
  margin-bottom: 6px;
}

.rightsec {
  display: flex;
  align-items: center;
  justify-content: end;
  ul {
    display: flex;
    // align-items: center;
    justify-content: end;
    li {
      list-style: none;
    }
  }
}

/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY START///////////////
  =======================================================*/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .uploadData {
    ul {
      li {
        line-height: 35px;
      }
    }
  }
}
