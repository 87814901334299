.modalButtonBottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;

  :global(.btn) {
    padding: 12px 40px;
    width: 48%;
    margin-top: 10px;
  }
}
